.login-screen {
   display: flex;
   align-items: center;
   justify-content: center;
   min-height: 80vh;

   .login-block {
      width: 500px;
      max-width: 500px;
      padding: 24px 20px;
      border: 1px solid #cde6f9;
      border-radius: 20px;
      background: #fdfdfd;
      padding-bottom: 10px;
      margin: 0 20px;

      .login-title {
         margin-bottom: 16px;
         font-size: 24px;
         font-weight: 600;
         text-align: center;
         color: #2f2e31;
      }
   }

   .login-form {
   }
}
