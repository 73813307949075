@font-face {
   font-family: 'InterFont';
   src:
      url('../assets/fonts/Inter-Italic-VariableFont.ttf') format('truetype'),
      url('../assets/fonts/Inter-VariableFont.ttf') format('truetype');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

body {
   font-family: 'InterFont', sans-serif;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './common.scss';
@import './login.scss';
@import './project.scss';
@import './statistic.scss';
