.statistic-number-component {
   font-weight: 600;
   font-size: 24px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: block;
   width: 100% !important;
}

.ant-statistic-content-value {
   width: 100%;
}
