.header {
   background: unset;
   display: flex;
   justify-content: center;
   z-index: 999;
   top: 0;
   position: sticky;
   width: 100%;
   height: 80px;
   padding: 0;
   box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);

   .logo-container {
      display: flex;
      gap: 30px;
      align-items: center;
      height: 100%;

      .logo-img {
         height: 40px;
      }
   }

   .header-dropdown {
      .user-name {
         color: #2f2e31;
         font-size: 16px;
         font-weight: 600;
      }
      // .ant-dropdown-menu-item,
      // .ant-dropdown-menu-item-only-child {
      //    font-size: 20px;
      //    min-width: 120px;
      // }
   }
}

.main-content {
   overflow: auto;
}

.footer {
   display: flex;
   justify-content: space-between;
   align-items: center;
   background: #fff;
   .copy-right {
      color: #787878;
      font-size: 14px;
      font-weight: 600;
   }
   img {
      height: 33.5px;
   }
}

.breadcrumb-block {
   font-size: 16px;
   margin-top: 10px;
   margin-bottom: 20px;
   font-weight: 400;

   .ant-breadcrumb-separator {
      margin-inline: 5px;
      color: black;
      font-weight: 600;
   }

   .ant-breadcrumb-link {
      color: #2f2e31;
      font-weight: 600;

      a {
         height: 100%;
         color: #2f2e31;
         font-weight: 400;
      }
   }
}

.flex-center {
   display: flex;
   align-items: center;
   justify-content: center;
}

.flex-between {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.printer-terminal-component {
}

.dot-flashing-component {
   width: 25px;

   .dot-flashing {
      position: relative;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: black;
      color: black;
      animation: dot-flashing 0.6s infinite linear alternate;
      animation-delay: 0.3s;
      margin-left: 10px;
      margin-right: 10px;
   }

   .dot-flashing::before,
   .dot-flashing::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
   }

   .dot-flashing::before {
      left: -10px;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: black;
      color: black;
      animation: dot-flashing 0.6s infinite alternate;
      animation-delay: 0s;
   }

   .dot-flashing::after {
      left: 10px;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: black;
      color: black;
      animation: dot-flashing 0.6s infinite alternate;
      animation-delay: 0.6s;
   }

   @keyframes dot-flashing {
      0% {
         background-color: black;
      }

      50%,
      100% {
         background-color: white;
      }
   }
}

/* Custom scroll bar works on FireFox */
body::-webkit-scrollbar {
   width: 12px;
}

body::-webkit-scrollbar-track {
   background: transparent;
}

body::-webkit-scrollbar-thumb {
   background-color: #0671c9;
   border-radius: 20px;
   border: 3px solid transparent;
}

* {
   scrollbar-width: thin;
   scrollbar-color: #0671c9 transparent;
}

/* Custom scroll bar works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
   width: 12px;
}

*::-webkit-scrollbar-track {
   background: transparent;
}

*::-webkit-scrollbar-thumb {
   background-color: #0671c9;
   border-radius: 20px;
   border: 3px solid transparent;
}

#tv-attr-logo {
   display: none;
}

.ant-spin-fullscreen,
.ant-spin-fullscreen-show {
   z-index: 99999 !important;
}

.ant-tabs-nav .ant-tabs-tab {
   margin: 0 !important;
   padding: 10px 16px;
   font-weight: 600;
}

.ant-tabs-nav-wrap {
   background: #fff;
   border-bottom: 1px solid #e7e7f6;
   border-radius: 5px;
}

.ant-table-wrapper {
   .ant-table {
      font-size: 14px;
      // box-shadow: 0px 2px 6px 0px rgba(59, 59, 66, 0.36);
      border: 1px solid #e7e7f6;
      border-bottom: none;
      background: #fff;
      line-height: normal;
   }
   .ant-table-pagination.ant-pagination {
      padding: 10px 0;
      margin: 0;
      background: #fff;
      // box-shadow: 0px 2px 6px 0px rgba(59, 59, 66, 0.36);
      border: 1px solid #e7e7f6;
      border-top: none;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      margin-bottom: 30px;
   }
   .ant-table-thead > tr > th {
      position: relative;
      border-bottom: none;
      border-bottom: 2px solid #0ca7ee !important;
   }
}

.ant-input-number-affix-wrapper:hover .ant-input-number-suffix {
   margin-inline-end: 11px;
}

.ant-card {
   box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
   background: #fff;
   border-radius: 10px !important;
   padding: 20px;
   .ant-card-body {
      padding: 0 !important;
   }
}

.ant-modal-footer {
   display: flex;
   margin-top: 5px;
   button {
      flex: 1;
      border-radius: 10px;
   }
   button:first-child {
      color: #fff;
      background: #787878;
   }
   .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: #787878 !important;
      border-color: unset !important;
      color: #fff !important;
   }
}

.antd-space-custom {
   .ant-space-item {
      width: 100%;
   }
   .ant-space-item:last-child {
      width: 30px;
      position: relative;
      div {
         cursor: pointer;
         position: absolute;
         bottom: 0;
      }
   }
}

.title-with-tooltip {
   font-size: 14px;
   font-weight: 400;
   color: #787878;
   margin-bottom: 16px;
}

.ant-table-cell {
   padding: 16px 10px !important;
}

.ant-layout-footer {
   padding-left: 0 !important;
   padding-right: 0 !important;
   @media (max-width: 650px) {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
   }
}

.text-checklist {
   font-size: 14px;
   font-style: normal;
   font-weight: 600;
   line-height: 20px;
   color: #585757;
}
.cp-checklist {
   list-style: disc;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   margin-top: -8px;
   margin-bottom: 16px;

   padding-left: 28px;

   &.remove-stype {
      list-style: none;
      padding-left: 0px;

      li {
         display: flex;
         align-items: center;
      }
   }

   li {
      margin-bottom: 8px;

      svg {
         margin-right: 8px;
         width: 20px;
         height: 20px;
      }
   }
}

// .ant-table-cell {
//    white-space: nowrap;
// }

@keyframes pulseActive {
   0% {
      transform: scale(1);
   }
   45% {
      transform: scale(1.03);
   }
   100% {
      transform: scale(1);
   }
}

.segmented-component {
   box-sizing: border-box;
   overflow-x: auto;
   /* Hide scroll */
   scrollbar-width: none; /* Firefox */
   -ms-overflow-style: none; /* IE and Edge */
   &::-webkit-scrollbar {
      /* Chrome, Safari, Opera */
      display: none;
   }
   scroll-behavior: smooth;

   .segmented-item {
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
      padding: 16px 20px;
      height: 100%;
      @media (max-width: 650px) {
         padding: 10px 12px;
      }
      width: 100%;
      cursor: pointer;
      border: 2px solid transparent;
      transition: border-color 0.2s ease;
      min-width: 160px;
      color: #787878;
      font-weight: 400;
      font-size: 16px;
   }

   .segmented-item-active {
      border-color: #0ca7ee;
      animation: pulseActive 0.2s ease;
      color: #2f2e31;
      font-weight: 600;
   }
}
